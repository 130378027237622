// store.js
import {thunk} from 'redux-thunk';
import { general_state_reducer } from './GeneralStateReducer';
import { auth_reducer } from './AuthReducer';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';

const rootReducer = combineReducers({
  general: general_state_reducer,
  auth: auth_reducer,
});

const composeEnhancer = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ? 
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    }) : compose;

const enhancer = composeEnhancer(
    applyMiddleware(thunk)
);

export const store = createStore(rootReducer, enhancer);
