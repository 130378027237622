import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import LoginPage from './Authentication/LoginPage';
import NavBar from './Navigation/NavBar';
import { useSelector, useDispatch } from 'react-redux';
import { refreshTokenIfNeeded } from '../state/redux/actions/AuthActions';
import PageNotFound from './404Page/404Page';

function App() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state: any) => state.auth);
  const location = useLocation();

  // Check for token in cookies on initial load
  useEffect(() => {
    const getCookie = (name: any) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop()?.split(';').shift();
      return null;
    };

    const token = getCookie('auth_token');
    const refresh_token = getCookie('refresh_token');
    if (token && refresh_token) {

      localStorage.setItem('token', token);
      localStorage.setItem('refreshToken', refresh_token);
      // If token exists in cookies, navigate to the main app
      refreshTokenIfNeeded(dispatch, navigate);
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    if (!token && localStorage.getItem('token')) {
      refreshTokenIfNeeded(dispatch, navigate);
    }
  }, [navigate, location.pathname]);

  return (
    <div className="App">
      <NavBar />
      <Routes>
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="/login" element={<LoginPage />} />

        {/* 404 Route */}
        <Route path="/*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default App;
